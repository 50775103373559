import { NextRouter } from "next/router";
import { PagesMain } from "shared/types/navigation";

const pathsPrivacy: Record<PagesMain, boolean> = {
    [PagesMain.index]: false,
    [PagesMain.contacts]: false,
    [PagesMain.vacancies]: false,
    [PagesMain.faq]: false,
    [PagesMain.catalog]: false,
    [PagesMain.custom404]: false,
    [PagesMain.about]: false,
    [PagesMain.news]: false,
    [PagesMain.newsArticle]: false,
    [PagesMain.resetPassword]: false,
    [PagesMain.page]: false,
    [PagesMain.cart]: false,
    [PagesMain.search]: false,
    [PagesMain.profileMain]: true,
    [PagesMain.profileUsers]: true,
    [PagesMain.profileSales]: true,
    [PagesMain.profileCompanies]: true,
    [PagesMain.product]: false,
    [PagesMain.profileOrders]: true,
    [PagesMain.profileFinances]: true,
    [PagesMain.profileHistory]: true,
    [PagesMain.commercialOffer]: true,
    [PagesMain.custom500]: false,
};

export const publicPaths: NextRouter["pathname"][] = Object.entries(pathsPrivacy)
    .filter(([_href, isPrivate]) => !isPrivate)
    .map(([href]) => href as NextRouter["pathname"]);

export const privatePaths: NextRouter["pathname"][] = Object.entries(pathsPrivacy)
    .filter(([_href, isPrivate]) => isPrivate)
    .map(([href]) => href as NextRouter["pathname"]);
