export enum Color {
    "main_Black" = "#111111",
    "main_MainDark" = "#2F2F2F",
    "main_MainDarkGray" = "#5C5964",
    "main_MainLightGray" = "#BCBBC0",

    "second_SecondDarkGray" = "#EEEEEE",
    "second_SecondLightGray" = "#F3F3F3",
    "second_white" = "#FFFFFF",

    "validation_violet" = "#692FBC",
    "validation_blue" = "#005AFF",
    "validation_green" = "#77CB59",
    "validation_red" = "#FF0000",

    "inherit" = "inherit",
    "transparent" = "transparent",
    "black" = "black",
    "white" = "white",
}

export enum WindowSize {
    desktopL = 1640,
    desktopM = 1280,
    tablet = 768,
    tabletH = 1024,
    laptop = 1024,
    mobile = 480,
}

export const contentMaxWidth = 1520;
export const sidePadding = 20;
export const contentMaxWidthWrapperClassname = "contentPaddingWrapper";
