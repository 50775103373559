import { PropsWithChildren } from "react";
import { Hydrate, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AppProps } from "next/app";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnReconnect: false,
            retry: false,
            onError: (err) => {
                console.error(err);
            },
        },
    },
});

function QueryProvider({ children, pageProps }: PropsWithChildren<AppProps["pageProps"]>) {
    return (
        <QueryClientProvider client={queryClient}>
            <Hydrate state={pageProps.dehydratedState}>{children}</Hydrate>
        </QueryClientProvider>
    );
}

export default QueryProvider;
