import Axios, { AxiosRequestHeaders } from "axios";
import { getCookie } from "cookies-next";
import { getDeviceId } from "shared/api/cart/utils/getDeviceId";
import { prepareRequestQuery } from "shared/utils/prepareRequestQuery";
import { defaultHeaders } from "src/application/config/axios/helpers";
import { responderInterceptor } from "src/application/config/axios/interceptors/response";
import { TAxiosRequestInterceptorSuccess, TAxiosResponseInterceptorSuccess } from "../../types";
//здесь все значения которые мы будем хранить в куках
export enum ECookies {
    UserRole = "UserRole",
    accessToken = "accessToken",
    refreshToken = "refreshToken",
    deviceId = "deviceId",
}

export const authUserInterceptor: TAxiosRequestInterceptorSuccess = (config) => {
    const bearerToken = getCookie(ECookies.accessToken);
    if (!bearerToken) return config;
    if (!config.headers) {
        config.headers = {} as AxiosRequestHeaders;
    }
    config.headers["Authorization"] = `Bearer ${bearerToken}`;
    return config;
};

export const fileInterceptor: TAxiosRequestInterceptorSuccess = (config) => {
    config.headers = {
        ...config.headers,
        Accept: "*/*",
    } as AxiosRequestHeaders;
    return config;
};

export const prepareQueryInterceptor: TAxiosRequestInterceptorSuccess = (config) => {
    const params = config.params;
    if (!params) {
        return config;
    }
    config.params = prepareRequestQuery(params);
    return config;
};

const deviceIdInstance = Axios.create({
    baseURL: process.env.NEXT_PUBLIC_BASE_URL,
    headers: defaultHeaders,
    responseType: "json",
});
deviceIdInstance.interceptors.response.use(responderInterceptor);

export const guestCartInterceptor: TAxiosRequestInterceptorSuccess = async (config) => {
    const deviceId = await getDeviceId(deviceIdInstance);
    config.params = { deviceId: deviceId, ...(config.params ?? {}) };
    return config;
};

export const deviceIdUserInterceptor: TAxiosRequestInterceptorSuccess = async (config) => {
    if (typeof window === "undefined") {
        config["headers"]["deviceId"] = "NextJS";
        return config;
    }
    const deviceId = await getDeviceId(deviceIdInstance);
    if (!deviceId) {
        return config;
    }
    config.headers["deviceId"] = deviceId;
    return config;
};

export const loggerInterceptor: TAxiosRequestInterceptorSuccess = async (config) => {
    console.info(`[axios] Request sent to: ${config?.url}`);
    return config;
};
export const loggerResponseInterceptor: TAxiosResponseInterceptorSuccess = async (config) => {
    console.info(`[axios] Response from url: ${config.config.url} => status: ${config.status}`);
    return config;
};
export const revalidationTokenServerSideInterceptor: TAxiosRequestInterceptorSuccess = async (config) => {
    const token = process.env.REVALIDATION_TOKEN;
    if (token) config.headers["X-Revalidation-Token"] = token;
    return config;
};
