import { z } from "zod";
// TODO собрать enum для всех страниц
export enum SlugStaticPage {
    ABOUT_COMPANY = "o-kompanii",
    PERSONAL_DATA_PROCESSING_POLICY = "politika-obrabotki-personalnyx-dannyx",
    CONSENT_TO_PERSONAL_DATA_PROCESSING = "soglasie-na-obrabotku-personalnyx-dannyx",
    REQUIREMENTS_FOR_MOCK_UP = "obshhie-trebovaniia-k-maketam",
}

export type MetaDataPage = z.infer<typeof $MetaDataPage>;
export const $MetaDataPage = z.object({
    tagMetaDescription: z.string(),
    tagMetaKeywords: z.string(),
    tagMetaTitle: z.string(),
    tagTitle: z.string(),
});

export type PageItem = z.infer<typeof $PageItem>;
export const $PageItem = z
    .object({
        id: z.number(),
        title: z.string(),
        slug: z.string().nullish(),
        menuLabel: z.string(),
        menuId: z.number(),
        content: z.string(),
    })
    .merge($MetaDataPage);

export const $MenuItem = z.object({
    id: z.number(),
    title: z.string(),
    pages: z.array($PageItem),
});
export type MenuItem = z.infer<typeof $MenuItem>;

export const $PagesIndexRequest = z.object({
    filtering: z
        .object({
            menuId: z.number().nullish(),
        })
        .nullish(),
});
export type PagesIndexRequest = z.infer<typeof $PagesIndexRequest>;
