import {
    $ImpersonateResponse,
    $LoginResponse,
    $RefreshResponse,
    ForgotPasswordRequest,
    ImpersonateRequest,
    ImpersonateResponse,
    LoginRequest,
    LoginResponse,
    RefreshRequest,
    RefreshResponse,
    UpdatePasswordRequest,
} from "shared/api/auth/types";
import { BaseApi } from "shared/api/baseApi";
import { $RegistrationResponse, $UserDataResponse, UserDataResponse } from "shared/api/user/types";

export class AuthApi extends BaseApi {
    async registration(body: unknown): Promise<unknown> {
        return $RegistrationResponse.parse(await this.instance.post("customers/register", body));
    }
    async login(payload: LoginRequest): Promise<LoginResponse> {
        return $LoginResponse.parse(await this.instance.post("auth/login", payload));
    }
    async shortLogin(payload: LoginRequest): Promise<LoginResponse> {
        return $LoginResponse.parse(await this.instance.post("auth/short-ttl-login", payload));
    }

    //Этот запрос осоебнный, у нас он вызывается с помощью другой сущности, которая не обрабатывает его стандартными интерсепторами.
    // Так что в этом случае рефреш респонс будет необработанный, т.е. использовать UnhandledResponse дженерик
    async refresh(payload: RefreshRequest): Promise<RefreshResponse> {
        const url =
            process.env.NEXT_PUBLIC_SHORT_REFRESH_TOKEN === "true"
                ? "auth/tokens/short-ttl-refresh"
                : "auth/tokens/refresh";

        //напрямую вызываем данный запрос из интерцепторов, так что проверяем енв здесь, а не в хуке, как логин
        return $RefreshResponse.parse(await this.instance.post(url, payload));
    }
    async logout(): Promise<unknown> {
        return this.instance.delete("auth/logout");
    }
    async userData(): Promise<UserDataResponse> {
        return $UserDataResponse.parse(await this.instance.get("customers/profile"));
    }
    async forgotPassword(payload: ForgotPasswordRequest): Promise<unknown> {
        return this.instance.post("auth/forgot-password", payload);
    }
    async updatePassword(payload: UpdatePasswordRequest): Promise<void> {
        return this.instance.post("auth/reset-password", payload);
    }

    async impersonate(payload: ImpersonateRequest): Promise<ImpersonateResponse> {
        return $ImpersonateResponse.parse(await this.instance.post("auth/impersonate", payload));
    }
}
