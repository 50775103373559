import { JSXElementConstructor, PropsWithChildren, ReactElement } from "react";
import NextAdapterPages from "next-query-params/pages";
import { QueryParamAdapter, QueryParamProvider as UQPQueryParamProvider } from "use-query-params";

function Adapter(props: {
    children: (adapter: QueryParamAdapter) => ReactElement<unknown, string | JSXElementConstructor<unknown>> | null;
}) {
    return <NextAdapterPages {...props} shallow={false} />;
}

function QueryParamProvider({ children }: PropsWithChildren) {
    return <UQPQueryParamProvider adapter={Adapter}>{children}</UQPQueryParamProvider>;
}

export default QueryParamProvider;
