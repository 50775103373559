import { createContext, PropsWithChildren } from "react";
import Script, { ScriptProps } from "next/script";
import { InitParameters } from "./types";
import { useYandexMetricaRouteTracker } from "./utils";
import { YandexMetricaImg } from "./YandexMetricaImg";

export const MetricaTagIDContext = createContext<number | null>(null);

interface YandexMetricaProviderProps {
    yandexMetricaID: number;
    initParameters?: InitParameters;
    strategy?: ScriptProps["strategy"];
}

export const YandexMetricaProvider = ({
    children,
    yandexMetricaID,
    initParameters,
    strategy = "afterInteractive",
}: PropsWithChildren<YandexMetricaProviderProps>) => {
    useYandexMetricaRouteTracker({ yandexMetricaID });

    if (!yandexMetricaID) {
        // eslint-disable-next-line no-console
        console.warn("[yandex-metrica-provider] Yandex.Metrica ID is not defined");

        return <>{children}</>;
    }

    return (
        <>
            <Script id="metrica-counter" strategy={strategy}>
                {`(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
              m[i].l=1*new Date();
              for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
              k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
              (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
 
              ym(${yandexMetricaID}, "init", ${JSON.stringify(initParameters || {})});
              `}
            </Script>
            <noscript id="metrica-counter-img">
                <YandexMetricaImg yandexMetricaID={yandexMetricaID} />
            </noscript>
            <MetricaTagIDContext.Provider value={yandexMetricaID}>{children}</MetricaTagIDContext.Provider>
        </>
    );
};
