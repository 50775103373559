import { $AdaptedPaginatedResponse, $PaginationFilter } from "shared/api/types";
import { z } from "zod";
import { $MetaDataPage } from "../pages/types";

export enum CategoryId {
    PAPER_PEN = 29,
    METAL_PEN = 24,
}

export type CategoriesResponse = z.infer<typeof $CategoriesResponse>;
export type CategoriesData = z.infer<typeof $CategoriesData>;
export type CategoriesRequest = z.infer<typeof $PaginationFilter>;

export const $SubCategory = z
    .object({
        id: z.number(),
        name: z.string(),
        slug: z.string().nullish(),
        isActive: z.boolean(),
    })
    .merge($MetaDataPage);

export const $CategoriesData = $SubCategory.extend({ subcategory: $SubCategory.array() });

export const $CategoriesResponse = $AdaptedPaginatedResponse(z.array($CategoriesData));

export const $CatalogViewVariantEnum = z.enum(["tiles", "row"]);

export const $CategoryFilterTypeEnum = z.enum(["checkbox", "multi_select", "multi_checkbox"]);
export const $CategoryFilter = z.object({
    displayName: z.string().nullish(),
    id: z.number(),
    name: z.string(),
    type: $CategoryFilterTypeEnum,
    options: z
        .object({
            id: z.number(),
            name: z.string(),
            value: z.string(),
        })
        .array(),
});
export type CategoryFilter = z.infer<typeof $CategoryFilter>;

export type CategoryShow = z.infer<typeof $CategoryShow>;
export const $CategoryShow = z
    .object({
        name: z.string(),
        title: z.string(),
        variantView: $CatalogViewVariantEnum,
        filters: $CategoryFilter.array(),
        description: z.string(),
    })
    .merge($MetaDataPage);
