import { AxiosInstance } from "axios";
import { getCookie, setCookie } from "cookies-next";
import { CartApi } from "shared/api/cart/requests";
import { ECookies } from "src/application/config/axios/interceptors/request";

export const getDeviceId = async (instance: AxiosInstance): Promise<string> => {
    const cookieDeviceId = getCookie(ECookies.deviceId);
    if (cookieDeviceId) {
        return String(cookieDeviceId);
    }
    const newDeviceId = (await new CartApi(instance).showNewCartId()).deviceId;
    setCookie(ECookies.deviceId, newDeviceId);
    return newDeviceId;
};
