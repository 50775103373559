// import React from "react";
import { ModalsProvider } from "@mantine/modals";
import { NotificationsProvider } from "@mantine/notifications";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { AppProps } from "next/app";
import {
    AuthProvider,
    QueryParamProvider,
    QueryProvider,
    ThemeProvider,
    YandexMetricaProvider,
} from "src/application/providers";
import "shared/utils/toNumber";
import "../application/styles/reset.css";

// const ReactQueryDevtoolsProduction = React.lazy(() =>
//     import("@tanstack/react-query-devtools/build/lib/index.prod.js").then((d) => ({
//         default: d.ReactQueryDevtools,
//     }))
// );
export default function App({ Component, pageProps }: AppProps) {
    return (
        <YandexMetricaProvider>
            <ThemeProvider>
                <QueryProvider pageProps={pageProps}>
                    <NotificationsProvider position="top-right" containerWidth={500} limit={1} zIndex={1000}>
                        <ModalsProvider>
                            <AuthProvider>
                                <QueryParamProvider>
                                    <Component {...pageProps} />
                                </QueryParamProvider>
                            </AuthProvider>
                            <ReactQueryDevtools initialIsOpen={false} />
                            {/* <React.Suspense fallback={null}>
                            <ReactQueryDevtoolsProduction />
                        </React.Suspense> */}
                        </ModalsProvider>
                    </NotificationsProvider>
                </QueryProvider>
            </ThemeProvider>
        </YandexMetricaProvider>
    );
}
