import { PropsWithChildren, useEffect, useState } from "react";
import { getCookie } from "cookies-next";
import { NextRouter, useRouter } from "next/router";
import useUserData from "shared/api/user/hooks/useUserData";
import { ECookies } from "src/application/config/axios/interceptors/request";
import { privatePaths, publicPaths } from "./utils";

export default function AuthProvider({ children }: PropsWithChildren<Record<string, unknown>>) {
    const router = useRouter();
    const { data } = useUserData();
    const [_showContent, setShowContent] = useState<boolean>(false);
    const accessToken = getCookie(ECookies.accessToken);

    useEffect(() => {
        checkUrl(router.route);
        let previousPathname: string | null = null;

        function handleRouteChange(url: string) {
            const pathname = url.split("?")[0];
            if (previousPathname && previousPathname !== pathname) {
                setShowContent(false);
            }
            previousPathname = pathname;
        }

        function handleRouteComplete() {
            checkUrl(router.route);
        }

        router.events.on("routeChangeStart", handleRouteChange);
        router.events.on("routeChangeComplete", handleRouteComplete);
        return () => {
            router.events.off("routeChangeStart", handleRouteChange);
            router.events.off("routeChangeComplete", handleRouteComplete);
        };
    }, [router.route]);

    useEffect(() => {
        if (privatePaths.includes(router.route) && !accessToken) {
            router.push("/404");
        }
    }, [router.route, data?.id]);

    function checkUrl(url: NextRouter["pathname"]) {
        const isAllowed = publicPaths.includes(url) || (privatePaths.includes(url) && !!accessToken);

        if (isAllowed) {
            return setShowContent(true);
        }

        // eslint-disable-next-line no-console
        return console.error(`Страница "${url}" не найдена либо для её посещения нет прав. Проверь navigation`);
    }

    return <>{children}</>;
}
