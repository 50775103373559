import Axios from "axios";
import { responderInterceptor } from "src/application/config/axios/interceptors/response";

export const instanceSsr = Axios.create({
    baseURL: process.env.NEXT_PUBLIC_BASE_URL,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Axios-Instance": 'Server-Side'
    },
});

// instanceSsr.interceptors.request.use(authUserInterceptor, errorResponseInterceptor);

instanceSsr.interceptors.response.use(responderInterceptor);
