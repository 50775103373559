import { prepareResponse } from "src/application/config/axios/interceptors/response/utils";
import { TAxiosResponseInterceptorSuccess, TFileDownloadResponse } from "../../types";

export const responderInterceptor: TAxiosResponseInterceptorSuccess = (response) => {
    if (response.config.responseType === "blob") {
        const filename = response.headers["content-disposition"]?.split("=")[1].replace('"', "");
        return { data: response.data, filename } as TFileDownloadResponse;
    }
    //здесь формируем удобный response
    try {
        return prepareResponse(response.data);
    } catch (error: unknown) {
        //обработка ошибок в респонсе происходит в errorResponseInterceptor
        return response;
    }
};
