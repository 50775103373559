import Axios, { AxiosError, AxiosRequestHeaders, isAxiosError } from "axios";
import { deleteCookie, getCookie, setCookie } from "cookies-next";
import { AuthApi } from "shared/api/auth/requests";
import { UserRoleId } from "shared/utils/enums";
import { handleShowToast } from "../react-toastify/utils";
import { ECookies } from "./interceptors/request";
import { instance } from "./default";
import { TAxiosResponseInterceptorError, TAxiosRunWhen } from "./types";

export const defaultHeaders: Partial<AxiosRequestHeaders> = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Axios-Instance": 'Client-Side'
};
export const refreshInstance = Axios.create({
    baseURL: process.env.NEXT_PUBLIC_BASE_URL,
    headers: defaultHeaders,
    responseType: "json",
});

const refreshApi = new AuthApi(refreshInstance);

/**
 *
 * @param config AxiosConfigObject
 * @returns true если запрос идет на урл не содержащий admin в URL, false иначе.
 */
export const whenUsingLkRoute: TAxiosRunWhen = (config) => {
    return config.url?.includes("admin") || false;
};
/**
 * @returns true если USER_TYPE === user, false иначе.
 */
export const withRole: TAxiosRunWhen = () => {
    return !!getCookie(ECookies.UserRole);
};

/**
 * @returns true если в localStorage есть accessToken
 */
export const whenRestoringSession: TAxiosRunWhen = () => {
    if (typeof window === "undefined") return false;
    return !!localStorage.getItem("accessToken");
};

/**
 * @returns true если USER_TYPE === admin, false иначе.
 */
export const whenAdminProjectUser: TAxiosRunWhen = () => {
    return getCookie(ECookies.UserRole) === UserRoleId.administrator;
};

/**
 *
 * @param config AxiosConfigObject
 * @returns true, если responseType === blob
 */
export const whenDownloadingFiles: TAxiosRunWhen = (config) => {
    return config.responseType === "blob";
};

/**
 * @param config AxiosConfigObject
 * @return true, если это гостевой роут корзины
 */
export const whenCartRoutes: TAxiosRunWhen = (config) => {
    if (!config.url) return false;
    return config.url.includes("/cart") && !config.url.includes("/cart/uuid");
};

export const errorLogger: TAxiosResponseInterceptorError = (error) => {
    // eslint-disable-next-line no-console

    if (isAxiosError(error)) {
        const url = error.config?.url;
        const status = error.response?.status;
        return console.error(`${status} ${url} ${JSON.stringify(error.response?.data)}`);
    }
    return console.error("[EL]:", error);
};

export interface IError {
    status: number;
    errors: string[];
}

type CallBack = (value: string) => void;

//очередь колбэков
let subscribers: CallBack[] = [];
//лоадер для очереди
let isAlreadyFetchingAccessToken = false;

export const errorResponseInterceptor: TAxiosResponseInterceptorError = (error: AxiosError<IError>) => {
    const errorCode = error.code;
    const originalRequestConfig = error.config;
    if (typeof window === "undefined") {
        console.error("Axios Error:");
        return Promise.reject(error);
    }
    const isNetworkError = errorCode === "ERR_NETWORK";
    const isAuthError = error.response?.data?.status === 401;
    const isRefreshURL =
        error.request.responseURL.split("/").includes("refresh") ||
        error.request.responseURL.split("/").includes("short-ttl-refresh");

    const accessToken = getCookie(ECookies.accessToken);
    const refreshToken = getCookie(ECookies.refreshToken);

    const deleteTokens = () => {
        deleteCookie(ECookies.accessToken);
        deleteCookie(ECookies.refreshToken);
        return Promise.reject(error);
    };

    const handleLogout = () => {
        window.location.href = "/logout";
    };

    function executeQueue(newAccessToken: string) {
        // таким способом убираем запрос из очереди после выполнения колбэка
        subscribers = subscribers.filter((callback) => callback(newAccessToken));
    }
    function addToQueue(callback: CallBack) {
        subscribers.push(callback);
    }

    if (isNetworkError) {
        error.message = "Ошибка сети";
        handleShowToast(error);
        return Promise.reject(error);
    }

    if (isAuthError) {
        if (isRefreshURL || !refreshToken || !accessToken) {
            return deleteTokens();
        }
        if (!isAlreadyFetchingAccessToken) {
            isAlreadyFetchingAccessToken = true;
            refreshApi
                .refresh({ refreshToken: String(refreshToken) })
                .then((response) => {
                    const { data } = response;
                    const newAccessToken = data.data?.accessToken ?? null;
                    const newRefreshToken = data.data?.refreshToken ?? null;
                    setCookie(ECookies.refreshToken, newRefreshToken);
                    setCookie(ECookies.accessToken, newAccessToken);
                    isAlreadyFetchingAccessToken = false;
                    executeQueue(newAccessToken);
                })
                .catch(handleLogout);
        }

        return new Promise((resolve) => {
            const callback = (access_token: string) => {
                if (originalRequestConfig?.headers) {
                    originalRequestConfig.headers.Authorization = access_token;
                    return resolve(instance(originalRequestConfig));
                }
            };
            addToQueue(callback);
        });
    }

    handleShowToast(error);
    return Promise.reject(error);
};
