import { EventParameters } from "widgets/YandexMetrica/types/events";

export const ym = (yandexMetricaID: number | null, ...parameters: EventParameters) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- ym создаётся через Yandex.Metrica script
    // @ts-ignore
    const ym = window.ym as YM | undefined;

    if (!ym || !yandexMetricaID) {
        return;
    }

    ym(yandexMetricaID, ...parameters);
};
