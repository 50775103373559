import React from "react";
import { MantineProvider } from "@mantine/core";
import { emotionCache } from "shared/utils/emotionCache/emotionCache";
import { defaultTheme } from "./theme";

type Props = {
    children: React.ReactNode;
};

export default function ThemeProvider({ children }: Props) {
    return (
        <MantineProvider
            emotionCache={emotionCache}
            withGlobalStyles
            withNormalizeCSS
            withCSSVariables
            theme={defaultTheme}>
            {children}
        </MantineProvider>
    );
}
