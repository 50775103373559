import { $AdaptedPaginatedResponse, $AdaptedResponse } from "shared/api/types";
import { z } from "zod";

export type IUser = z.infer<typeof $UserData>;

export type UserDataResponse = z.infer<typeof $UserDataResponse>;

export type IProfile = z.infer<typeof $IProfile>;

export type UserContactsResponse = z.infer<typeof $UserContactsResponse>;

export const $Role = z.object({
    id: z.number(),
    displayName: z.string(),
    name: z.string(),
});

export const $User = z
    .object({
        id: z.number(),
        email: z.string(),
        fullName: z.string().nullable(),
        phone: z.string().nullable(),
    })
    .partial();

export const $Company = z
    .object({
        id: z.number(),
        email: z.string().nullish(),
        title: z.string(),
        phone: z.string(),
        manager: $User.nullish(),
    })
    .partial();

export const $IProfile = z.object({
    id: z.number(),
    fullName: z.string().nullish(),
    phone: z.string().nullish(),
    userId: z.number().nullish(),
    jobTitle: z.string().nullish(),
    client: $User.nullish(),
    company: $Company.nullish(),
});

export const $UserData = z.object({
    id: z.number(),
    email: z.string(),
    isActive: z.boolean().nullish(),
    customerProfile: $IProfile,
    roles: $Role.array(),
});

export const $Contractor = z.object({
    id: z.number(),
    title: z.string(),
    inn: z.string().optional(),
    kpp: z.string().optional(),
    address: z.string(),
});

export const $DiscountToCategories = z.object({
    id: z.number(),
    displayName: z.string(),
    value: z.number(),
    unit: z.string(),
});
export const $Discount = z.object({
    // процент скидки в накопительной системе
    percent: z.number(),
    items: z.array($DiscountToCategories),
});

export const $MonthStatistic = z.object({
    //формат 2022-07-01 00:00:00
    month: z.string(),
    sum: z.number(),
});

export type StatisticResponse = z.infer<typeof $Statistic>;

export const $Statistic = z.object({
    clients: z.array(
        z.object({
            id: z.number(),
            displayName: z.string(),
            sum: z.number(),
            hasDebt: z.boolean(),
        })
    ),
    yearToYearOverview: z.object({
        lastYearSum: z.number(),
        thisYearSum: z.number(),
        dynamic: z.number(),
    }),
    financeHistoryData: z.object({
        previousYear: z.array($MonthStatistic),
        currentYear: z.array($MonthStatistic),
    }),
});

export const $UpdateUserDataRequest = z.object({
    fullName: z.string(),
    phone: z.string(),
});

export const $UpdatePasswordRequest = z.object({
    oldPassword: z.string(),
    newPassword: z.string(),
    newPasswordConfirmation: z.string(),
});
export const $UserContactsData = z.object({
    id: z.number(),
    email: z.string(),
    fullName: z.string(),
    phone: z.string(),
    roles: $Role.array(),
});

export const $RegistrationsData = z.null();

export const $UserDataResponse = $AdaptedResponse($UserData);

export const $RegistrationResponse = $AdaptedResponse($RegistrationsData);

export const $UserContactsResponse = $AdaptedPaginatedResponse($UserContactsData.array());

export const $CreateContractorRequest = $Contractor.omit({ id: true });

export type CreateContractorRequest = z.infer<typeof $CreateContractorRequest>;

export type UsersResponse = IUser[];

export type IUserContact = z.infer<typeof $UserContactsData>;

export type UpdateUserDataRequest = z.infer<typeof $UpdateUserDataRequest>;

export type UpdatePasswordRequest = z.infer<typeof $UpdatePasswordRequest>;

export type IUserConractor = z.infer<typeof $Contractor>;

export interface IPagination {
    count: number;
    total: number;
    perPage: number;
    currentPage: number;
    totalPages: number;
    links: unknown;
}
