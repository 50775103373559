import { isExist } from "shared/utils/guards";

const transformValue = (value: unknown): string | undefined => {
    const valueType = typeof value;
    if (
        valueType == "undefined" ||
        valueType == "object" ||
        valueType == "symbol" ||
        valueType == "function" ||
        value === null
    )
        return;
    // адаптация для бэкэнда
    if (valueType == "boolean") {
        return value == true ? "1" : "0";
    }
    if (!String(value).trim()) return;
    return String(value);
};

const generateData = (value: unknown, parentKey: string): { [key: string]: string } | undefined => {
    if (typeof value != "object") {
        const newValue = transformValue(value);
        if (!isExist(newValue)) return;
        return {
            [parentKey]: newValue,
        };
    }
    if (!value) return;
    let newParams: { [key: string]: string } = {};
    for (const key in value) {
        const subValue = value[key as keyof typeof value];
        const complexKey = `${parentKey}[${key}]`;
        newParams = { ...newParams, ...generateData(subValue, complexKey) };
    }
    return newParams;
};

export const prepareRequestQuery = <T extends object>(params: T | undefined): { [key: string]: string } => {
    let newParams: { [key: string]: string } = {};
    if (!params) return newParams;
    for (const key in params) {
        const value = params[key];
        newParams = { ...newParams, ...generateData(value, key) };
    }
    return newParams;
};
