import React from "react";

interface YandexMetricaImgProps {
    yandexMetricaID: number;
}

export const YandexMetricaImg = ({ yandexMetricaID }: YandexMetricaImgProps) => (
    // это яндекс метрика тут не нужны оптимизации картинок
    // eslint-disable-next-line @next/next/no-img-element
    <img
        height="1"
        width="1"
        style={{ display: "none" }}
        src={`https://mc.yandex.ru/watch/${yandexMetricaID}`}
        alt=""
    />
);
