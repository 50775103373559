export enum UserRoleId {
    user = "1",
    manager = "2",
    administrator = "3",
    // возможно есть другие
    client = "6",
}

export enum UserRole {
    //TODO другие роли
    client = "CLIENT",
}
