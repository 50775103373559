export enum QueryKeys {
    USER_DATA = "USER_DATA",
    USERS = "USERS",
    VACANCIES_INDEX = "VACANCIES_INDEX",
    VACANCY_SHOW = "VACANCY_SHOW",
    QUESTIONS_INDEX = "QUESTIONS_INDEX",
    QUESTIONS_CATEGORIES_INDEX = "QUESTIONS_CATEGORIES_INDEX",
    QUESTIONS_SHOW = "QUESTIONS_SHOW",
    PRODUCT_CATEGORIES = "PRODUCT_CATEGORIES",
    CONTACTS_INDEX = "CONTACTS_INDEX",
    BANNER_INDEX = "BANNER_INDEX",
    NEWS_INDEX = "NEWS_INDEX",
    NEWS_SHOW = "NEWS_SHOW",
    MENUS_INDEX = "PAGES_MENUS_INDEX",
    PAGES_INDEX = "PAGES_INDEX",
    PAGES_SHOW = "PAGES_SHOW",
    CATEGORIES_INDEX = "CATEGORIES_INDEX",
    CATEGORIES_SHOW = "CATEGORIES_SHOW",
    PRODUCTS_SHOW = "PRODUCTS_SHOW",
    PRODUCTS_INDEX = "PRODUCTS_INDEX",
    PRODUCTS_SEARCH = "PRODUCTS_SEARCH",
    PRODUCTS_BY_CATEGORY_GET = "PRODUCTS_BY_CATEGORY_GET",
    PRODUCTS_BY_CATEGORY_GET_INFINITE = "PRODUCTS_BY_CATEGORY_GET_INFINITE",
    PRODUCTS_INDEX_INFINITE = "PRODUCTS_INDEX_INFINITE",
    PRODUCTS_SEARCH_INFINITE = "PRODUCTS_SEARCH_INFINITE",
    PRODUCTS_OFFER_INDEX = "PRODUCTS_OFFER_INDEX",
    PRODUCTS_OFFER_SHOW = "PRODUCTS_OFFER_SHOW",
    CART_COUNT_SHOW = "CART_COUNT_SHOW",
    CART_INDEX = "CART_INDEX",
    USER_CONTRACTORS_INDEX = "USER_CONTRACTORS_INDEX",
    USER_DISCOUTS_INDEX = "USER_DISCOUTS_INDEX",
    USER_STATISTIC_INDEX = "USER_STATISTIC_INDEX",
    USER_AUTH_SHOW = "USER_AUTH_SHOW",
    USER_CONTACTS_INDEX = "USER_CONTACTS_INDEX",
    COMMERCIAL_OFFER_SHOW = "COMMERCIAL_OFFER_SHOW",
    COMMERCIAL_OFFER_GET = "COMMERCIAL_OFFER_GET",
    ORDERS_INDEX = "ORDERS_INDEX",
    ORDERS_INDEX_INFINITE = "ORDERS_INDEX_INFINITE",
    SHIPPING_METHODS_GET = "SHIPPING_METHODS_GET",
}

export enum MutationKeys {
    LOGIN = "LOGIN",
    LOGOUT = "LOGOUT",
    REGISTRATION = "REGISTRATION",
    FORGOT_PASSWORD = "FORGOT_PASSWORD",
    UPDATE_PASSWORD = "UPDATE_PASSWORD",
    APPLY_FOR_JOB = "APPLY_FOR_JOB",
    FEEDBACK_CREATE = "FEEDBACK_CREATE",
    CART_ITEM_UPDATE = "CART_ITEM_UPDATE",
    CART_ITEM_REMOVE = "CART_ITEM_REMOVE",
    CART_ITEM_CREATE = "CART_ITEM_CREATE",
    CART_REMOVE = "CART_REMOVE",
    CART_UPDATE = "CART_UPDATE",
    FILES_CREATE = "FILES_CREATE",
    UPDATE_USER_DATA = "UPDATE_USER_DATA",
    UPDATE_USER_PASSWORD = "UPDATE_USER_PASSWORD",
    COMMERCIAL_OFFER_CREATE = "COMMERCIAL_OFFER_CREATE",
    COMMERCIAL_OFFER_UPDATE = "COMMERCIAL_OFFER_UPDATE",
    COMMERCIAL_OFFER_DELETE = "COMMERCIAL_OFFER_DELETE",
    COMMERCIAL_OFFER_UPDATE_COMMENT = "COMMERCIAL_OFFER_UPDATE_COMMENT",
    ORDER_CREATE = "ORDER_CREATE",
    USER_DATA_UPDATE = "USER_DATA_UPDATE",
    USER_PASSWORD_UPDATE = "USER_PASSWORD_UPDATE",
    USER_CONTRACTOR_CREATE = "USER_CONTRACTOR_CREATE",
    SUBSCRIBE = "SUBSCRIBE",
    AUTH_IMPERSONATE = "AUTH_IMPERSONATE",
}
