import { useQuery } from "@tanstack/react-query";
import { getCookie } from "cookies-next";
import { authApi } from "shared/api/auth";
import { QueryKeys } from "shared/api/keys";
import { ECookies } from "src/application/config/axios/interceptors/request";

const useUserData = () => {
    return useQuery([QueryKeys.USER_DATA], () => authApi.userData(), {
        enabled: !!getCookie(ECookies.accessToken),
        cacheTime: 0,
        staleTime: 0,
        placeholderData: null,
    });
};

export default useUserData;
