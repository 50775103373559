import { useEffect } from "react";
import { Router } from "next/router";
import { ym } from "../../ym";

export const useYandexMetricaRouteTracker = ({ yandexMetricaID }: { yandexMetricaID: number | null }) => {
    useEffect(() => {
        const handleRouteChange = (url: URL): void => ym(yandexMetricaID, "hit", url.toString());

        Router.events.on("routeChangeComplete", handleRouteChange);

        return () => {
            Router.events.off("routeChangeComplete", handleRouteChange);
        };
    }, [yandexMetricaID]);
};
