import { LinkProps } from "next/link";
import { Route } from "nextjs-routes";
import { TError } from ".";

export const isNotArray = <T>(item?: T | T[]): item is T => {
    return !Array.isArray(item);
};
export function isExist<T>(data: T | null | undefined): data is T {
    return data !== undefined && data !== null;
}

export const isErrorWithValidation = (item?: TError | unknown): item is TError => {
    return !!Object.keys((item as TError)?.response?.data.errors.validation ?? {}).length;
};

export const isErrorWithFields = (item?: TError | unknown): item is TError => {
    return !!Object.keys((item as TError)?.response?.data.errors ?? {}).length;
};

export const isLinkRoute = (item?: LinkProps["href"] | Route): item is Route => {
    return !!(item as Route)?.pathname;
};
