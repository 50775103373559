import { z, ZodTypeAny } from "zod";

export const $Date = z.coerce.date();

// обработка всех запросов у которых есть только data
export function $AdaptedResponse<T extends ZodTypeAny>(schema: T): T {
    return schema;
}
export type AdaptedResponse = z.infer<ReturnType<typeof $AdaptedResponse>>;

//обработка запросов с пагинацией
export function $AdaptedPaginatedResponse<T extends ZodTypeAny>(schema: T) {
    return z.object(
        {
            data: schema,
            pagination: $ApiPaginationType,
        },
        {
            invalid_type_error: "Запрос без пагинации",
        }
    );
}
export type AdaptedPaginatedResponse = z.infer<ReturnType<typeof $AdaptedPaginatedResponse>>;

//обработка запросов без интерсепторов (как пришло с бэка)
export function $UnhandledResponse<T extends ZodTypeAny>(schema: T) {
    //здесь ещё есть много неперечисленных свойств, таких как config, status, success и других
    return z.object({
        data: z.object({ data: schema }),
    });
}

export type UnhandledResponse = z.infer<ReturnType<typeof $UnhandledResponse>>;

export const $ApiFileType = z.object({
    id: z.number(),
    name: z.string(),
    path: z.string().url(),
    size: z.coerce.number(),
    type: z.string(),
});
export type ApiFileType = z.infer<typeof $ApiFileType>;

export const $ApiLayoutFileType = z.object({
    collection_name: z.string(),
    conversions_disk: z.string(),
    created_at: z.string(),
    custom_properties: z.unknown().array(),
    disk: z.string(),
    file_name: z.string(),
    generated_conversions: z.unknown().array(),
    id: z.number(),
    manipulations: z.unknown().array(),
    mime_type: z.string(),
    model_id: z.number(),
    model_type: z.string(),
    name: z.string(),
    order_column: z.number(),
    original_url: z.string(),
    preview_url: z.string().nullish(),
    responsive_images: z.unknown().array(),
    size: z.number(),
    updated_at: z.string(),
    uuid: z.string(),
});
export type ApiLayoutFileType = z.infer<typeof $ApiLayoutFileType>;

export const $ApiPaginationType = z.object({
    count: z.number(),
    total: z.number(),
    perPage: z.number(),
    currentPage: z.number(),
    totalPages: z.number(),
    links: z
        .object({
            next: z.string().nullish(),
            previous: z.string().nullish(),
            last: z.string().nullish(),
            first: z.string().nullish(),
        })
        .or(z.unknown().array()),
});
export type ApiPaginationType = z.infer<typeof $ApiPaginationType>;

export const $PaginationFilter = z.object({
    page: z.number().nullish(),
    perPage: z.number().nullish(),
});

export const $QueryFilter = z.object({
    query: z.string().nullish(),
});

export type PaginationFilter = z.infer<typeof $PaginationFilter>;

export type ApiSortType = "asc" | "desc";

export interface NameId {
    name: string;
    id: string | number;
}
