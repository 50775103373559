export enum CartErrorType {
    MAX_QUANTITY_ERROR = "maxQuantityError",
}

export type CartContextValue = {
    setMaxQuantityError: (value: { id: number; hasError: boolean }) => void;
    hasMaxQuantityError: boolean;
};

export type CartError = { cartItemId: number; typeError: CartErrorType };
