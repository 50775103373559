import { PropsWithChildren } from "react";
import { YandexMetricaProvider as YandexMetricaWidget } from "widgets/YandexMetrica";

export const YandexMetricaProvider = ({ children }: PropsWithChildren) => {
    if (!process.env.NEXT_PUBLIC_YANDEX_METRICA_ID || process.env.NODE_ENV === "development") {
        return <>{children}</>;
    }

    return (
        <YandexMetricaWidget
            yandexMetricaID={Number(process.env.NEXT_PUBLIC_YANDEX_METRICA_ID)}
            initParameters={{
                clickmap: true,
                trackLinks: true,
                accurateTrackBounce: true,
                webvisor: true,
                ecommerce: "dataLayer",
            }}>
            {children}
        </YandexMetricaWidget>
    );
};
