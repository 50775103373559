import localFont from "next/font/local";

export const montserrat = localFont({
    variable: "--font-montserrat",
    fallback: ["sans-serif"],
    src: [
        {
            path: "../../../../public/fonts/montserrat/Montserrat-Regular-400.ttf",
            style: "normal",
            weight: "400",
        },
        {
            path: "../../../../public/fonts/montserrat/Montserrat-Medium-500.ttf",
            style: "normal",
            weight: "500",
        },
        {
            path: "../../../../public/fonts/montserrat/Montserrat-SemiBold-600.ttf",
            style: "normal",
            weight: "600",
        },
        {
            path: "../../../../public/fonts/montserrat/Montserrat-Bold-700.ttf",
            style: "normal",
            weight: "700",
        },

        {
            path: "../../../../public/fonts/montserrat/Montserrat-Italic-400.ttf",
            style: "italic",
            weight: "400",
        },
        {
            path: "../../../../public/fonts/montserrat/Montserrat-MediumItalic-500.ttf",
            style: "italic",
            weight: "500",
        },
        {
            path: "../../../../public/fonts/montserrat/Montserrat-SemiBoldItalic-600.ttf",
            style: "italic",
            weight: "600",
        },
        {
            path: "../../../../public/fonts/montserrat/Montserrat-BoldItalic-700.ttf",
            style: "italic",
            weight: "700",
        },
    ],
});

export const openSans = localFont({
    variable: "--font-openSans",
    fallback: ["sans-serif"],
    src: [
        {
            path: "../../../../public/fonts/openSans/open-sans-v34-cyrillic_latin-regular.woff",
            style: "normal",
            weight: "400",
        },
        {
            path: "../../../../public/fonts/openSans/open-sans-v34-cyrillic_latin-italic.woff",
            style: "italic",
            weight: "400",
        },
        {
            path: "../../../../public/fonts/openSans/open-sans-v34-cyrillic_latin-600.woff",
            style: "normal",
            weight: "600",
        },
        {
            path: "../../../../public/fonts/openSans/open-sans-v34-cyrillic_latin-600italic.woff",
            style: "italic",
            weight: "600",
        },
    ],
});
