import { $AdaptedResponse, $UnhandledResponse } from "shared/api/types";
import { $Role } from "shared/api/user/types";
import { z } from "zod";

export type RegistrationRequest = z.infer<typeof $RegistrationRequest>;
export type RefreshRequest = z.infer<typeof $RefreshRequest>;
export type LoginResponse = z.infer<typeof $LoginResponse>;
export type RefreshResponse = z.infer<typeof $RefreshResponse>;
export type LoginRequest = z.infer<typeof $LoginRequest>;
export type ForgotPasswordRequest = z.infer<typeof $ForgotPasswordRequest>;
export type UpdatePasswordRequest = z.infer<typeof $UpdatePasswordRequest>;

export const $LoginData = z.object({
    accessToken: z.string(),
    refreshToken: z.string(),
    roles: $Role.array(),
});

export const $LoginRequest = z.object({
    email: z.string(),
    password: z.string(),
});

export const $LoginResponse = $AdaptedResponse($LoginData);

export const $RefreshData = z.object({
    accessToken: z.string(),
    refreshToken: z.string(),
});

export const $RefreshResponse = $UnhandledResponse($RefreshData);

export const $RefreshRequest = z.object({
    refreshToken: z.string(),
});

export const $ForgotPasswordRequest = z.object({
    email: z.string(),
});

export const $UpdatePasswordRequest = z.object({
    token: z.string(),
    email: z.string(),
    password: z.string(),
    passwordConfirmation: z.string(),
});

export const $RegistrationRequest = z.object({
    fullName: z.string().nullish(),
    email: z.string({ required_error: "Обязательное поле" }).email("Некорректный email"),
    password: z.string({ required_error: "Обязательное поле" }),
    passwordConfirmation: z.string({ required_error: "Обязательное поле" }),
});

export type ImpersonateRequest = z.infer<typeof $ImpersonateRequest>;
export const $ImpersonateRequest = z.object({ token: z.string() });

export type ImpersonateResponse = z.infer<typeof $ImpersonateResponse>;
export const $ImpersonateResponse = $LoginResponse;
