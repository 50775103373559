import { BreadCrumbsProps } from "src/shared/ui/BreadCrumbs";

export enum PagesMain {
    index = "/",
    contacts = "/contacts",
    vacancies = "/vacancies",
    faq = "/faq",
    catalog = "/catalog/[categoryId]",
    custom404 = "/404",
    about = "/about",
    news = "/news",
    newsArticle = "/news/[id]",
    //здесь snakeCase связан с тем, что эту ссылку формирует бэкэнд
    resetPassword = "/password-reset",
    page = "/page/[slug]",
    cart = "/cart",
    search = "/search",
    profileMain = "/profile/main",
    profileUsers = "/profile/users",
    profileSales = "/profile/sales",
    profileCompanies = "/profile/companies",
    product = "/catalog/[categoryId]/[productId]/[offerId]",
    profileOrders = "/profile/orders",
    profileFinances = "/profile/finances",
    profileHistory = "/profile/history",
    commercialOffer = "/commercial-offer/[id]",
    custom500 = "/500",
}

export type TMetaDataPage = {
    title?: string | null;
    metaTagDescription?: string | null;
    metaTagKeywords?: string | null;
    metaTagTitle?: string | null;
    canonicalHref?: string | null;
};
export interface IPageLayout extends TMetaDataPage {
    children: React.ReactNode;
    crumbs?: BreadCrumbsProps["list"];
}

export interface IPage extends Omit<IPageLayout, "children"> {
    url: string;
    Layout?: React.FC<IPageLayout>;
    Component: React.FC;
    private?: boolean;
}
